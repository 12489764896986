body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#app {
  max-width: 850px;
  margin: 0 auto;
}

nav {
  padding: 20px 0 20px 0;
}
/*_loginForm.scss */
.form-error{
  color: #F42B4B;
  text-align: center;
}

.form-success{
  color: rgb(13, 163, 13);
  text-align: center;
}

.form-warning{
  color: rgb(226, 176, 7);
  text-align: center;
}
.svgIcon {
	vertical-align: middle;
	fill: rgba(0, 0, 0, 0.54);
  padding-right: 0px;
  padding-left: 0px;
	height: 37px;
	display: inline-block;
}


.login-line span {
 
  display: inline-block;
  padding: 0 10px;
  position: relative;
  /*top: -10px; da Punkte leider trotz padding durchgehend sind... */
}
.login-line {
  border-top: 2px dotted #d1d1d1;
  text-align: center;
  margin: 25px;
  font-weight: 700;      
}